if (document.querySelector(".scheduleSwiper")) {


    const swiper = new Swiper(".scheduleSwiper", {
        slidesPerView: 1.3,
        spaceBetween: 0,
        freeMode: true,

        navigation: {
            nextEl: ".scheduleSwiper .swiper-button-next",
            prevEl: ".scheduleSwiper .swiper-button-prev",
        },
        scrollbar: {
            el: ".scheduleSwiper .swiper-scrollbar",
            draggable: true,
        },


        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 5,
            },
        },
        on: {
            afterInit: function () {
                console.log("xxx");
                if (!document.body.classList.contains("body-en")) {

                    document.querySelector(".scheduleSwiper .swiper-button-prev").setAttribute("aria-label", "Poprzedni slajd");
                    document.querySelector(".scheduleSwiper .swiper-button-next").setAttribute("aria-label", "Następny slajd");
                }
            }
        },


    });


}
